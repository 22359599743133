/*Common*/
div[class^='MuiButton-root-'] {
  text-transform: capitalize;
}

/*Clients*/
div[class^='MuiSelect-select-']:focus {
  background-color: transparent;
}
div[class^='MuiInput-underline-']:after {
  border: none;
}

/*AvailabilityCalendar*/
table#RBS-Scheduler-root.scheduler {
  border-color: white !important;
  border: none;
}

table#RBS-Scheduler-root button {
  color: #ffffff;
  font-size: 12px;
  margin-left: 10px;
}

table#RBS-Scheduler-root .resource-table {
  text-align: left;
}

table#RBS-Scheduler-root {
  background-color: white;
}

table#RBS-Scheduler-root.scheduler thead .header2-text {
  font-size: 20px;
  padding: 10px;
}

table#RBS-Scheduler-root.scheduler thead .ant-col {
  width: 100%;
}

table#RBS-Scheduler-root.scheduler thead .ant-col div.header2-text {
  display: flex;
  justify-content: space-between;
}

table#RBS-Scheduler-root div.resource-view {
  border: 1px solid #ffffff;
}

table#RBS-Scheduler-root table.resource-table td {
  border-bottom: 1px solid #ffffff;
}

table#RBS-Scheduler-root table.scheduler-content-table {
  border-top-color: #ffffff;
  border-left-color: #ffffff;
  border-right-color: #ffffff;
  border-bottom-color: #ffffff;
}

table#RBS-Scheduler-root table.scheduler-bg-table {
  border-top-color: #ffffff;
  border-left-color: #ffffff;
  border-right-color: #ffffff;
  border-bottom-color: #ffffff;
}

table#RBS-Scheduler-root table.scheduler-bg-table td,
td {
  border-top-color: #ffffff;
  border-left-color: #ffffff;
  border-right-color: #ffffff;
  border-bottom-color: #ffffff;
}

table#RBS-Scheduler-root .scheduler-view {
  border-color: #ffffff;
}

table#RBS-Scheduler-root table.resource-table td {
  border-collapse: inherit;
  display: flex;
  flex-direction: column;
}
